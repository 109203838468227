import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Availability from "../Components/Tour/Availability";
import Contents from "../Components/Tour/Contents";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import { getTour } from "../Api/TourApi";
import { Image, Transformation, Placeholder } from "cloudinary-react";
import Lightbox from "../Components/Lightbox";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  MdStairs,
  MdStroller,
  MdTrendingFlat,
  MdNoStroller,
} from "react-icons/md";
import Banner from "../Components/Banner";

const TourPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [tourInfo, setTourInfo] = useState({});
  const history = useHistory();

  const fixedslug = props.match.params.slug.replace(".html", "");
  const current = new Date();

  useEffect(() => {
    let cancelled = false;

    async function fetchTour() {
      const response = await getTour(fixedslug);
      // console.log(response);
      if (!response.data[0]) {
        history.push("/");
      }
      if (!cancelled) {
        setTourInfo(response.data[0]["attributes"]); //0 index because same method for one or all
        setLoading(false);
      }
    }
    fetchTour();

    return () => {
      cancelled = true;
    };
  }, [fixedslug, history]);

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: tourInfo.title,
    description: tourInfo.description,
    brand: {
      "@type": "Brand",
      name: "American Legacy Tours",
    },
    image:
      "https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
      tourInfo.picturePath,
    offers: {
      "@type": "Offer",
      url: "https://www.americanlegacytours.com/" + fixedslug,
      price: tourInfo.price,
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
      priceValidUntil: "12/31/" + current.getFullYear(),
    },
  };

  return (
    <>
      {loading && (
        // <div className="App" style={{ height: "4000px" }}>
        //   Loading...
        // </div>
        <Row style={{ textAlign: "center", height: "4000px" }}>
          <Col xs="12">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}

      {!loading && (
        <>
          <Helmet>
            <title>{tourInfo.title + " | American Legacy Tours"}</title>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
          </Helmet>
          <section
            className="pageTitle"
            style={{
              padding: "30px",
              color: "#ffffff",
              // height: "200px",
              backgroundImage: tourInfo.topPath
                ? "url('https://res.cloudinary.com/american-legacy-tours/image/upload/f_auto,q_auto" +
                  tourInfo.topPath +
                  "')"
                : "url('https://www.americanlegacytours.com/img/pages/page-title-bg.jpg')",
              backgroundPosition: "center",
            }}
          >
            <Container>
              <Col>
                <div className="titleTable">
                  <div className="titleTableInner">
                    <div
                      className="pageTitleInfo"
                      style={{ textAlign: "center" }}
                    >
                      <h1>{tourInfo.title}</h1>
                    </div>
                  </div>
                </div>
              </Col>
            </Container>
          </section>

          <Container>
            <Row style={{ margin: "10px" }}>
              <Col sm={3}>
                <div style={{ textAlign: "center" }}>
                  <Image
                    cloudName="american-legacy-tours"
                    publicId={tourInfo.picturePath}
                    style={{ height: "200px" }}
                    loading="lazy"
                    fetch_format="auto"
                  />
                  <a id="buybutton" href={tourInfo.pathTitle + "/buy"}>
                    <Image
                      cloudName="american-legacy-tours"
                      publicId="/img/clicktobuy"
                      style={{ width: "80%" }}
                      loading="lazy"
                      fetch_format="auto"
                    />
                  </a>
                </div>
              </Col>
              <Col sm={9}>
                {/* <h2>{tourInfo.title}</h2> */}
                <br />
                {tourInfo.description && tourInfo.description.length > 0 && (
                  <>
                    {tourInfo.description}
                    <br />
                    <br />
                  </>
                )}
                {tourInfo.description2 && tourInfo.description2.length > 0 && (
                  <>
                    {tourInfo.description2}
                    <br />
                    <br />
                  </>
                )}
                {tourInfo.description3 && tourInfo.description3.length > 0 && (
                  <>
                    {tourInfo.description3}
                    <br />
                  </>
                )}
                <br />
                {tourInfo.groups.split(",").map((group) => (
                  <span style={{ margin: 5 }}>
                    <span
                      style={{
                        padding: "2px",
                        border: "1px solid #c0c0c0",
                        textTransform: "capitalize",
                      }}
                    >
                      {group}
                    </span>
                  </span>
                ))}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={7}>
                <a id="buybutton" href={tourInfo.pathTitle + "/buy"}>
                  <Banner/>
                </a>
                <Availability
                  thundertixId={tourInfo.thundertixId}
                  slug={tourInfo.pathTitle}
                  price={tourInfo.price}
                  title={tourInfo.title}
                  description={tourInfo.description}
                  startLocationName={tourInfo.startLocationName}
                  address={tourInfo.address}
                  city={tourInfo.city}
                  zip={tourInfo.zip}
                  state={tourInfo.state}
                  performanceId={tourInfo.performanceId}
                  picturePath={tourInfo.picturePath}
                />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "150%",
                    marginBottom: 20,
                  }}
                >
                  <a href={fixedslug + "/buy"}>See All Available Dates</a>
                  <hr />
                </div>
              </Col>
              <Col lg={5}>
                {tourInfo.difficulty && (
                  <>
                    <h2>Intensity</h2>
                    {tourInfo.difficulty}
                    <br />
                    {tourInfo.stairs && (
                      <>
                        <MdStairs size="3em" /> Includes stairs
                      </>
                    )}
                    {!tourInfo.stairs && (
                      <>
                        <MdTrendingFlat size="3em" /> Flat terrain
                      </>
                    )}
                    <br />
                    {tourInfo.stroller && (
                      <>
                        <MdStroller size="3em" /> Stroller Friendly
                      </>
                    )}
                    {!tourInfo.stroller && (
                      <>
                        <MdNoStroller size="3em" /> Not Stroller Friendly
                      </>
                    )}
                    <hr />
                    <h2>Rated {tourInfo.rating}</h2>
                    {tourInfo.ratingContent}
                    <br />
                    {tourInfo.twentyone && <h3>Age: 21+ only</h3>}
                    <hr />
                  </>
                )}
                {tourInfo.duration && (
                  <>
                    <h2>Duration</h2>
                    {tourInfo.duration}
                  </>
                )}
              </Col>
            </Row>
          </Container>
          <hr />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h2>Tour Begins</h2>
                  <h4>{tourInfo.startLocationName}</h4>
                  <address>
                    {tourInfo.address}
                    <br />
                    {tourInfo.city}, {tourInfo.state}
                    {tourInfo.zip}
                  </address>
                  <hr />
          <Container>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <Image
                cloudName="american-legacy-tours"
                publicId="/img/home/tripadvisor-logo"
                style={{ width: 290, height: 44 }}
                width="290"
                height="44"
                loading="lazy"
                fetch_format="auto"
              >
                <Placeholder type="predominant" />
                <Transformation height="44" width="290" />
              </Image>
              <br />
              <h4 style={{ color: "#262a2e" }}>
                2022, 2023, &amp; 2024 Travelers Choice Award and Certificate of Excellence
              </h4>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <Image
                cloudName="american-legacy-tours"
                publicId="/img/home/tripadvisor1"
                style={{ width: "119", height: "20" }}
                width="119"
                height="20"
                loading="lazy"
                fetch_format="auto"
              >
                <Placeholder type="predominant" />
                <Transformation height="20" width="119" />
              </Image>
              &nbsp;
              <a
                href="https://www.tripadvisor.com/Attraction_Review-g60993-d4432364-Reviews-American_Legacy_Tours-Cincinnati_Ohio.html"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  color: "#000000",
                  textDecoration: "underline",
                }}
              >
                <span id="tripadvisorreviewcount">3,309</span> Reviews
              </a>
              <a
                href="https://www.tripadvisor.com/Attraction_Review-g60993-d4432364-Reviews-American_Legacy_Tours-Cincinnati_Ohio.html"
                className="btn buttonCustomPrimary"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Details
              </a>
            </Col>
          </Row>
        </Container>
                </div>
                <div className="col-md-8">
                  <iframe
                    title="googlemap"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: "0" }}
                    src={
                      "https://www.google.com/maps/embed/v1/place?q=" +
                      encodeURI(
                        tourInfo.address +
                          " " +
                          tourInfo.city +
                          " " +
                          tourInfo.state +
                          " " +
                          tourInfo.zip
                      ) +
                      "&key=AIzaSyAF0pVwRqx_wSImGwi80X7chUgzKdq1Q_E"
                    }
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <hr />
              <Lightbox slug={tourInfo.pathTitle} id={tourInfo.id} />
              <hr />

              {/* <Photos slug={slug} id={tourInfo.id} />
          <hr /> */}

              <Contents slug={tourInfo.pathTitle} id={tourInfo.id} />
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default TourPage;
